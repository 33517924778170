::selection {
  background: #4d4d4d; /* WebKit/Blink Browsers */
  color: #eeeeee; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #4d4d4d; /* WebKit/Blink Browsers */
  color: #eee; /* WebKit/Blink Browsers */
}

html {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

#backgroundVideo {
  position: relative;
  z-index: -500;
}

.inlineLink {
  color: #000;
  transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0);
  font-weight: bold;
  text-decoration: none;
  margin-top: 20;
  border-radius: 4;
}

.inlineLink:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.1);
}
.inlineLink:active {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.2);
}


/* --------------------------------------------------------------------------------------------- */
/* NEW TEXT STYLES */

/* overall */
.brand {
  font-family: "Brand";
  /* font-family: 'Open Sans', sans-serif; */
}
.code {
  font-family: 'Inconsolata', monospace;
}
.tag {
  font-family: 'Inconsolata', monospace;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.12em;
  font-weight: bold;
  color: #4d4d4d;
  padding: 5px 10px;
  width: fit-content;
  background-color: #00000010;
  border-radius: 4px;
  cursor: crosshair;
}
.tag2 {
  font-family: 'Inconsolata', monospace;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.12em;
  font-weight: bold;
  color: #000000;
  padding: 5px 10px;
  width: fit-content;
  background-color: #EDEDED;
}
.sectionTitle {
  font-family: 'Inconsolata', monospace;
  font-size: 28px;
  font-weight: bold;
}

/* links */
.linkText {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  color: #6D0FE5;
  text-decoration: none;
  padding-right: 6;
}
.link {
  transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0);
}
.link:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.05);
}
.link:active {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.1);
}

/* scrolling text */
.scrollingText {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 90s linear infinite;
  -webkit-animation: my-animation 90s linear infinite;
  animation: my-animation 90s linear infinite;
}
/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}
/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}
@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


/* ABOUT PAGE TEXT STYLES */

.heading {
  text-align: left;
  letter-spacing: 0.03em;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.heading2 {
  text-align: left;
  letter-spacing: 0.03em;
  font-size: 24px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.75;
  color: #1e1e1e
}

.bodyBig {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.75;
  color: #1e1e1e
}
.body {
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.75;
  color: #1e1e1e
}
.bodyLight {
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.75;
  color: #525252
}
.body2 {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.50;
  color: #1e1e1e
}
.body2Light {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.50;
  color: #525252
}
.aboutBody {
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.5;
  color: #1e1e1e
}

/* Work section + Gallery titles */
.title {
  letter-spacing: 0.03em;
  font-size: 18px;
  text-decoration: none;
  color: #000000;
  height: 100%;
  padding-bottom: 20;
}


/* --------------------------------------------------------------------------------------------- */
/* BUTTON ANIMATIONS */

.button {
  transition: all 0.1s;
  border: 1px solid #000;
  padding: 18px 24px;
  margin: 18px 0px;
  border-radius: 8px;
}
.button:hover {
  box-shadow: 0 3px #000;
  transform: translateY(-3px);
  border-radius: 8px;
}
.button:active{
  transition: all 0.1s;
  box-shadow: 0 0px #000;
  transform: translateY(2px);
  border-radius: 8px;
}

.imgButton {
  transition: all 0.1s;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
}
.imgButton:hover {
  border: 1px solid #000;
  box-shadow: 0 4px #000;
  transform: translateY(-3px);
  border-radius: 16px;
}
.imgButton:active{
  transition: all 0.1s;
  border: 1px solid #000;
  box-shadow: 0 0px #000;
  transform: translateY(2px);
  border-radius: 16px;
}

.project {
  border: 1px solid #e4e4e4;
  transition: all 0.1s ease-in-out;
}
.project:hover {
  border: 1px solid #000;
  box-shadow: 0 4px #000;
  transform: translateY(-3px);
}
.project:active {
  transition: all 0.1s;
  border: 1px solid #000;
  box-shadow: 0 0px #000;
  transform: translateY(2px);
}
.project .details{
  transform: translateY(-100%);
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.project:hover .details{
  transform: translateY(0%);
  opacity: 1;
  max-height: 500px;
  transition: max-height 0.5s ease-in-out;
}

/* scroll to top button */
.circleButton {
  cursor: pointer;
  border-radius: 100px;
  transition: all 0.1s;
  border: 1px solid #000;
  width: 42px;
  height: 42px;
}
.circleButton:hover {
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0 3px #000;
  transform: translateY(-3px);
}
.circleButton:active {
  cursor: pointer;
  border-radius: 100px;
  transition: all 0.1s;
  box-shadow: 0 0px #000;
  transform: translateY(2px);
}
.slideOutDown {
  opacity: 0;
  transform: translateY(100%);
  visibility: hidden;
  transition: all .2s;
}
.slideOutUp {
  opacity: 0;
  transform: translateY(-100%);
  visibility: hidden;
  transition: all .2s;
}
.slideIn {
  opacity: 1;
  visibility: visible;
  transition: all .2s;
}

/* scroll to section button */
.iconButton {
  transition: all 0.1s;
}
.iconButton:hover {
  transform: translateY(-6px);
}
.iconButton:active {
  transition: all 0.1s;
  transform: translateY(2px);
}


/* --------------------------------------------------------------------------------------------- */
/* PLAY */

.pillOption {
  transition: all 0.1s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 100px;
}

.pillOptionActive {
  border: 1px solid #7b7b7b;
  background-color: rgba(255,255,255,1);
}

.pillOptionInactive {
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0);
}

.pillOptionInactive:hover {
  transition: all 0.1s ease-in-out;
  background-color: rgba(0, 0, 0, 0.08);
}


/* --------------------------------------------------------------------------------------------- */
/* NAV */

.invisible {
  transition: all 0.2s ease-out;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.visible {
  transition: all 0.2s ease-out;
  visibility: visible;
  opacity: 1;
  height: auto;
}

.line1 {
  transition: transform .35s ease-in-out;
}

.line1x {
  transform: translateY(8px) rotate(45deg);
  transition: transform 0.35s ease-in-out;
}

.line2 {
  transition: transform .35s ease-in-out;
}

.line2x {
  transform: rotate(45deg);
  transition: transform 0.35s ease-in-out;
}

.line3 {
  transition: transform .35s ease-in-out;
}

.line3x {
  transform: translateY(-8px) rotate(-45deg);
  transition: transform 0.35s ease-in-out;
}

.navContainer {
  transition: height 0.2s ease-in-out;
  position: fixed;
  display: block;
  width: 100%;
  z-index: 900;
  height: 64px;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
  border-bottom: 2px solid rgba(0,0,0,0.05);
}

.navContainerOpen {
  transition: height 0.2s ease-in-out;
  position: fixed;
  display: block;
  width: 100%;
  z-index: 900;
  height: 250px;
  background-color: rgba(255,255,255,1);
  backdrop-filter: blur(0px);
  border-bottom: 2px solid rgba(0,0,0,0.1);
}



/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

/* UNUSED */

/* rainbow */
@keyframes rainbowText {
  0% {
    color: #FF5454;
  }
  
  10% {
    color: #FFDB5E;
  }
  
  20% {
    color: #ECE42E;
  }
  
  30% {
    color: #6CF500;
  }
  
  40% {
    color: #00D789;
  }
  
  50% {
    color: #00FFF0;
  }
  
  60% {
    color: #00A3FF;
  }
  
  70% {
    color: #7241FF;
  }

  80% {
    color: #CC00FF;
  }
  
  90% {
    color: #FF00A8;
  }
  
  100% {
    color: #FF5454;
  }
}

/* rainbow */
@keyframes rainbowRing {
  0% {
    border: 2px solid #FF5454;
  }
  
  10% {
    border: 2px solid #FFDB5E;
  }
  
  20% {
    border: 2px solid #ECE42E;
  }
  
  30% {
    border: 2px solid #6CF500;
  }
  
  40% {
    border: 2px solid #00D789;
  }
  
  50% {
    border: 2px solid #00FFF0;
  }
  
  60% {
    border: 2px solid #00A3FF;
  }
  
  70% {
    border: 2px solid #7241FF;
  }

  80% {
    border: 2px solid #CC00FF;
  }
  
  90% {
    border: 2px solid #FF00A8;
  }
  
  100% {
    border: 2px solid #FF5454;
  }
}

/* gradient ring */
@keyframes gradientRing {
  0% {
    border: 2px solid #00FFF0;
  }
  25% {
    border: 2px solid #64DAFF;
  }
  50% {
    border: 2px solid #7241FF;
  }
  
  75% {
    border: 2px solid #CC00FF;
  }
  100% {
    border: 2px solid #FF00A8;
  }
}

/* gradient text */
@keyframes gradientText {
  0% {
    color: #00FFF0;
  }
  25% {
    color: #64DAFF;
  }
  50% {
    color: #7241FF;
  }
  75% {
    color: #CC00FF;
  }
  100% {
    color: #FF00A8;
  }
}

.rainbow {
  display: inline-block;
  font-weight: medium;
  animation: rainbowText 20s infinite;
  transition: transform 1s ease-in-out;
  transform: translateY(0px) rotate(0deg);
  cursor: crosshair;
}

.rainbow:hover {
  transform: translateY(0px) rotate(1075deg);
  transition: transform 1s ease-in-out;
}

/* about */
.tagInvisible {
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
}
.tagVisible {
  opacity: 1;
  visibility: visible;
  transition: all .2s;
}
.social {
  opacity: 1;
  transition: all .2s;
}
.social:hover {
  opacity: 0.2;
}
.socialLight {
  opacity: 0.4;
  transition: all .2s;
}
.socialLight:hover {
  opacity: 1;
}

/* about popup */
.invisibleBlackTint {
  transition: all 0.2s ease-out;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.visibleBlackTint {
  transition: all 0.2s ease-out;
  visibility: visible;
  opacity: 0.4;
  height: auto;
}
.invisiblePopup {
  transition: all 0.2s ease-out;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.visiblePopup {
  transition: all 0.2s ease-out;
  visibility: visible;
  opacity: 1;
  height: auto;
}
.imageBig {
  animation: rainbowRing 20s infinite;
  transform: scale(3,3) translateY(18px);
  transition: transform 0.35s ease-in-out;
  position: fixed;
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.25);
}
.imageSmall {
  animation: rainbowRing 20s infinite;
  transition: transform 0.3s ease-in-out;
  border-width: 2px;
}
.imageSmall:hover {
  transform: scale(1.2,1.2) rotate(5deg) translateY(-2px);
  transition: transform 0.0.2s ease-in-out;
}


/* Dashed list */
ul.dashed {
  padding-left: 0;
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -10px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}